import {Header} from "../Components/Header/Header";
import {LandingComponent} from "../Components/Landing/LandingComponent";
import girlImg from "../Assets/images/girl.png";

export const Landing = () => {
    return <>
        <div className={'w-full flex flex-row bg-container poppin-text h-screen'}>
            {/*<div className={'z-[1] bg-blur h-screen w-full'}>*/}
                <div className={'z-[5] poppin-text text-white w-full'}>
                    <Header />
                    <LandingComponent />
                </div>
            {/*</div>*/}
            {/*<div className={'girl-img absolute bottom-0 right-2 z-[2] w-0 md:w-5/12'}>*/}
            {/*    <img src={girlImg} className={''} alt={'girl-img'} />*/}
            {/*</div>*/}
        </div>
    </>
}