import Logo from "../../Assets/images/coin_3.png";
import paperIcon from "../../Assets/images/document-text.svg";
import twitterIcon from "../../Assets/images/twitter.svg";
import discordIcon from "../../Assets/images/discord.svg";
import smsIcon from "../../Assets/images/sms-notification.svg";
import "./Header.css";

export const Header = () => {
    return <>
        <div className={'w-full flex flex-row justify-between items-center fixed py-3 px-4 z-10'}>
            <div className={'logo flex items-center ml-0 sm:ml-4'}>
                <img src={Logo} className={'w-12'} alt={'logo'}/>
            </div>
            <div className={`flex flex-row gap-x-3`}>
                <a href={'mailto:hi@tokenroyale.io'} className={'sms-btn flex items-center rounded-full border-2 border-[#7F3EE2] text-white px-2'}>
                    <img src={smsIcon} className={'w-6'} alt={'sms'}/>
                </a>
                <a href={'https://twitter.com/tokenroyaleio'} target={'_blank'} className={'twitter-btn flex items-center rounded-full border-2 border-[#1D9BF0] text-white px-2'}>
                    <img src={twitterIcon} className={'w-6'} alt={'twitter'}/>
                </a>
                {/*<a href={'https://discord.gg/PwwqwBW3Zg'} target={'_blank'} className={'discord-btn flex items-center rounded-full border-2 border-[#5865F2] text-white px-2'}>*/}
                {/*    <img src={discordIcon} className={'w-6'} alt={'discord'}/>*/}
                {/*</a>*/}
                <a href={'https://whitepaper.tokenroyale.io/'} target={'_blank'} className={'whitepaper-btn sm:hidden flex items-center rounded-full border-2 border-[#5865F2] text-white px-2 py-2'}>
                    <img src={paperIcon} className={'w-6'} alt={'paper-icon'}/>
                </a>
                <a href={'https://whitepaper.tokenroyale.io/'} target={'_blank'} className={'whitepaper-btn hidden sm:flex flex-row items-center justify-center py-2 px-7 rounded-full border-2 border-[#007AFF] font-semibold text-white gap-x-1'}><img src={paperIcon} className={'w-6'} alt={'paper-icon'}/> Whitepaper</a>
            </div>
        </div>
    </>
}