import './App.css';
import {BrowserRouter, Routes, Route, Navigate} from "react-router-dom";
import {Landing} from "./Pages/Landing";

function App() {
    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Landing />}></Route>
                </Routes>

            </BrowserRouter>
        </>
    );
}

export default App;
