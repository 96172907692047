
import royalText from "../../Assets/images/tokenroyaletext.png";
import flashIcon from "../../Assets/images/flash.svg";
import elixirImg from "../../Assets/images/elixir.svg";
import polygonIcon from "../../Assets/images/polygon.svg";

import "./Landing.css";
export const LandingComponent = () => {
    return <>
        <div className={'pt-32 sm:pt-16 md:pt-20 flex flex-row items-center w-full justify-center sm:justify-between px-4 md:px-8 '}>
            <div className={'w-full sm:w-5/12 mx-4 md:mt-8 2xl:mt-16 sm:mx-16 gap-y-4 md:gap-y-3 xl:gap-y-6 2xl:gap-y-8 flex flex-col justify-center md:justify-start'}>
                <div className={'flex justify-center'}>
                    <img src={royalText} className={'w-[400px] sm:w-[250px] md:w-[450px] xl:w-[550px] 2xl:w-[650px]'} alt={'royal-text'} />
                </div>
                <div className={'text-sm sm:text-base md:text-xl xl:text-2xl 2xl:text-3xl text-center italic'}>"Take Aim, Take Risks, Take Home the Rewards"</div>
                <div className={'flex justify-center'}>
                    <a href={'https://docs.google.com/forms/d/e/1FAIpQLSfAURuVSprPlm2aRo-ywOOFlrINfYq7kCmwy5HPKY0IIi7AHQ/viewform '} className={'join-btn flex flex-row items-center justify-center w-[200px] md:w-[250px] 2xl:w-[325px] gap-x-3 py-2 xl:py-3 border-2 border-[#DCBA04] rounded-full text-sm sm:text-base md:text-lg xl:text-xl 2xl:text-2xl font-semibold'} target={'_blank'}>
                        <img src={flashIcon} className={'w-6'} alt={'flash-icon'} />
                        Join Beta
                    </a>
                </div>
                <div className={'text-[#B7B4B4] flex flex-row items-center justify-center gap-x-2 text-[16px] md:text-[18px] 2xl:text-[24px] font-semibold'}>Available on <a href={'https://launcher.elixir.app/games/token-royale'} target={'_blank'}><img src={elixirImg} className={'h-4 md:h-5 2xl:h-6'} alt={'elixir-logo'} /></a> </div>
                <div className={'gap-x-2 flex flex-row justify-center text-sm sm:text-base xl:text-xl 2xl:text-2xl font-semibold'}><img src={polygonIcon} className={'w-5 md:w-7'} alt={'polygon-icon'}/> polygon </div>
            </div>

        </div>
    </>
}