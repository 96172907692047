import React from 'react';
import {createSlice} from '@reduxjs/toolkit'
import {toast} from "react-toastify";
import {smsSlice} from "./smsSlice";

const notify = (title, body, type) => toast(<><span className="notification-title">{title}</span> <span
    className="notification-body">{body}</span></>, {
    type: type,
    className: 'toast--' + type,
})

const initialState = {
    show: false
}

const notificationSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        show(state, action) {
            const {title, body, type} = action.payload
            notify(title, body, type)
        },
    }
});

// Action creators are generated for each case reducer function
export const {show} = notificationSlice.actions

export default notificationSlice.reducer